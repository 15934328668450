import { LOADING_OPTIONS } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
import GooglePlay from '@images/google-play-badge.svg';
// Perks images
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';
import HassleFree from '@images/product-page/hassle-free.svg';
import UnlockWorldwide from '@images/product-page/unlock-worldwide.svg';
import ZeroLogs from '@images/product-page/zero-logs.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForAndroidTv:metaData.title'),
    description: t('vpnForAndroidTv:metaData.description'),
    image: META_IMAGE,
    keywords: [
      'vpn for android tv',
      'vpn service for android tv',
      'vpn on android tv box',
      'android tv vpn app',
      'free vpn for smart tv',
      'free Android TV VPN ',
    ],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAndroidTv:headerCta.title'),
      subtitle: t('vpnForAndroidTv:headerCta.subtitle'),
      description: [
        { title: t('vpnForAndroidTv:headerCta.productFeatures.optimizedServers') },
        { title: t('vpnForAndroidTv:headerCta.productFeatures.changeLocation') },
        { title: t('vpnForAndroidTv:headerCta.productFeatures.unlimitedBandwidth') },
        { title: t('vpnForAndroidTv:headerCta.productFeatures.quickSetup') },
      ],
      buttons: [
        {
          title: t('common:actions.download'),
          link: APPS_LINKS.android,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.androidTv },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/android-tv-header-image-mobile.png'),
        alt: 'android tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/android-tv-header-image-tablet.png'),
        alt: 'android tv page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/android-tv-header-image.png'),
        alt: 'android tv page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2375px',
        position: {
          top: -80,
          height: 723,
          maxWidth: 2375,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForAndroidTv:stepCards.title'),
    description: t('vpnForAndroidTv:stepCards.description'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForAndroidTv:stepCards.downloadInstall.title'),
        description: t('vpnForAndroidTv:stepCards.downloadInstall.description'),
        button: {
          image: { src: GooglePlay, alt: 'Get it on Google Play' },
          handleOnClick: () => {
            window.open(APPS_LINKS.android, '_blank').focus();
            trackCustomGA4Event({
              eventName: GA4_EVENTS.appDownload,
              params: APP_PARAMS.androidTv,
            });
          },
        },
      },
      {
        image: UpgradeAnytime,
        step: 2,
        title: t('vpnForAndroidTv:stepCards.upgradeAnytime.title'),
        description: t('vpnForAndroidTv:stepCards.upgradeAnytime.description'),
      },
      {
        image: ConnectEnjoy,
        step: 3,
        title: t('vpnForAndroidTv:stepCards.connectEnjoy.title'),
        description: t('vpnForAndroidTv:stepCards.connectEnjoy.description'),
      },
    ],
  }),
  digitalLife: (t) => ({
    title: t('vpnForAndroidTv:elevateStreamExperience'),
    description: t('vpnForAndroidTv:joinUs'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/high-speed.png'),
        title: t('vpnForAndroidTv:contentBlocks.speed.title'),
        description: t('vpnForAndroidTv:contentBlocks.speed.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: UnlockWorldwide,
        title: t('vpnForAndroidTv:perkCards.unlockWorldwide.title'),
        description: t('vpnForAndroidTv:perkCards.unlockWorldwide.description'),
      },
      {
        image: UnlimitedAccess,
        title: t('vpnForAndroidTv:perkCards.unlimitedAccess.title'),
        description: t('vpnForAndroidTv:perkCards.unlimitedAccess.description'),
      },
    ],
  }),
  onlineSafety: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForAndroidTv:contentBlocks.protection.title'),
        description: t('vpnForAndroidTv:contentBlocks.protection.description'),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
    items: [
      {
        image: ZeroLogs,
        title: t('vpnForAndroidTv:perkCards.zeroLogs.title'),
        description: t('vpnForAndroidTv:perkCards.zeroLogs.description'),
      },
      {
        image: HassleFree,
        title: t('vpnForAndroidTv:perkCards.hassleFree.title'),
        description: t('vpnForAndroidTv:perkCards.hassleFree.description'),
      },
    ],
  }),
  reviews: (t) => ({
    title: t('vpnForAndroidTv:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForAndroidTv:pricingSection.title'),
    description: t('vpnForAndroidTv:pricingSection.description'),
    footerProps: {
      text: t('vpnForAndroidTv:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.android,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.androidTv },
        },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Is Atlas VPN a free VPN for smart TV?',
        content:
          'Currently, we do not offer a  free Android TV VPN service. Atlas VPN app for Android TV is only available with our premium subscription. However, you can download Atlas VPN apps for Windows, macOS, Android, and iOS completely free and enjoy improved privacy and safety online.',
      },
      {
        header: 'What are the benefits of a premium subscription?',
        listLabel:
          'Apart from giving you access to our Android TV VPN app, as well as the Atlas VPN Amazon Fire TV app, our Premium subscription provides you with many more benefits:',
        listItems: [
          `Choose from more than ${SERVER_COUNT} lightning-fast servers in ${locationCount} locations worldwide`,
          'Enhance your security with our Privacy Pro servers',
          'Enjoy round-the-clock customer support and no data limits',
        ],
      },
      {
        header: 'Is VPN service for Android TV necessary?',
        content: `A VPN can better your online experience while on Android TV. Connect to one of Atlas VPN’s ${SERVER_COUNT}+ servers in ${locationCount} countries and enjoy your favorite content wherever you are.\n\nVPN service for Android TV will keep all your activity private and secure by encrypting your internet traffic so no one can spy on what you do online and steal your data.`,
      },
      {
        header: 'Does Atlas VPN work with all smart TVs?',
        content:
          'Atlas VPN for Android TV works with any smart TV running Android 7 version or above. We also offer a VPN app for Amazon Fire TV users.',
      },
      {
        header: 'How many devices can I use Atlas VPN with?',
        content:
          'There is no limit to the number of devices you can connect to Atlas VPN. With a single subscription, you can protect all of your family’s devices simultaneously. We offer VPN for Android TV, Amazon Fire TV, Android, iOS, macOS, and Windows.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAndroidTv:footerCta.title'),
      description: t('vpnForAndroidTv:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'Android tv footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'Android tv footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'Android tv footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'Android tv footer',
      },
      desktop: {
        src: footerImage,
        alt: 'Android tv footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
