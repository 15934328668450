import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import {
  CTASection,
  CardsBlockSection,
  CompatibleDevices,
  ContentBlockSection,
  FAQSection,
  Reviews,
} from '@containers';
import { CTAFeatures, Card, Layout, SEO, Shape, Text } from '@components';
import { PAGE_DATA } from '@shared/constants/pages/product-page-android-tv';
import { useServersQuery } from '@data/servers';

const AndroidTVPage = ({ location, data }) => {
  const {
    metaData,
    headerCta,
    compatibleDevices,
    footerCta,
    steps,
    reviews,
    perksBefore,
    perksAfter,
    digitalLife,
    onlineSafety,
    moneyBackGuarantee,
    faq,
  } = PAGE_DATA({ images: data.allFile.edges });

  const { t } = useTranslation();

  const { locationCount } = useServersQuery();

  return (
    <Layout
      location={location}
      wrapperProps={{ backgroundColor: 'unset' }}
      navProps={{ initialBackgroundColor: { _: '#BFE2FF', lg: 'transparent' } }}
    >
      <SEO {...metaData(t)} />
      <CTASection
        data={headerCta}
        descriptionComponent={CTAFeatures}
        sectionProps={{
          wrapperProps: { pt: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
          pt: 80,
        }}
        contentProps={{
          pt: 120,
          pb: 160,
        }}
      />
      <CompatibleDevices
        {...compatibleDevices(t)}
        showDivider
        desktopCard={Card.IconLink}
        sectionProps={{ wrapperProps: { pb: { _: 80, lg: 120 } } }}
      />
      <CardsBlockSection
        {...steps({ t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={3}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <ContentBlockSection
        {...digitalLife(t)}
        shape={Shape.Blob2}
        shapeProps={{ left: '35%', top: { _: '5%', md: 0 } }}
        sectionProps={{ wrapperProps: { pt: { _: 64, md: 88 } } }}
      />
      <CardsBlockSection
        {...perksBefore(t)}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { py: { md: 64 } } }}
      />
      <ContentBlockSection
        {...onlineSafety(t)}
        shape={Shape.Blob3}
        shapeProps={{
          left: { _: '-40%', md: '40%' },
          top: { _: '15%', md: 0 },
          height: { _: '90%', md: '100%' },
        }}
      />
      <CardsBlockSection
        {...perksAfter({ t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <Reviews {...reviews(t)} />
      <ContentBlockSection
        {...moneyBackGuarantee(t)}
        shape={Shape.Blob4}
        shapeProps={{ left: { _: '10%', md: '50%' }, top: { _: '5%', md: 0 } }}
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
      />
      <FAQSection {...faq({ locationCount })} />
      <CTASection
        data={footerCta}
        showAvailablePlatforms
        titleComponent={Text.Heading2}
        sectionProps={{
          wrapperProps: { pb: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          pt: 160,
          pb: 80,
        }}
      />
    </Layout>
  );
};

export default AndroidTVPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "vpnForAndroidTv", "plans", "checkout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "product-page/android-tv-header-image.png"
            "product-page/android-tv-header-image-tablet.png"
            "product-page/android-tv-header-image-mobile.png"
            "product-page/high-speed.png"
            "product-page/safety-for-activities.png"
            "product-page/30-day-guarantee.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
